






























import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi } from 'ag-grid-community'
import http from '@/shared/http'
import DataTable from '@/shared/modules/data-table/data-table'
import ResponseInterface from '@/shared/interfaces/response.interface'
import DataTableRow from '@/shared/modules/data-table-row/data-table-row'
import { GlobalActions } from '@/shared/store/global/global.actions'
import SpreadSheetHeaderComponent from '@/shared/components/spreadsheet/SpreadSheetHeaderComponent.vue'
import _ from 'lodash'
import CSVToArray from '@/shared/helpers/csv-to-array.helper'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { PageHeader, AgGridVue }
})
export default class DataTableSpreadSheetDialog extends Vue {
  @Prop() meta!: any

  columnDefs: any[] = []
  rowData: any[] = []
  defaultColDef: any = {
    flex: 1,
    minWidth: 100,
    editable: true,
    lockPosition: true,
    sortable: true,
    filter: true,
    stopEditingWhenGridLosesFocus: true,
  }
  gridOptions: any = {}
  gridApi: GridApi | null = null
  gridColumnApi: any | null = null
  loading: boolean = false
  frameworkComponents = { agColumnHeader: SpreadSheetHeaderComponent }

  mounted() {
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
  }

  async created() {
    const dataTable: DataTable = await http.get(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/data-tables/${ this.meta.dataTable.uuid }`)
      .then((response: ResponseInterface<DataTable>) => response.data)
      .then((data: DataTable) => data)


    dataTable.rows.map((row: DataTableRow, index: number) => {
      Object.keys(row.data).forEach((key: any) => {
        if (this.columnDefs.filter((item: any) => item.headerName === key).length === 0) {
          this.columnDefs.push({
            headerName: key,
            field: key,
          })
        }
      })

      this.rowData.push(row.data)

      if (index !== dataTable.rows.length - 1) return
    })

    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns()
    })
  }

  addRow() {
    if (this.gridApi?.getColumnDefs().length === 0) {
      this.gridApi.setColumnDefs([
        {
          field: 'column',
          headerName: 'column'
        }
      ])
    }

    const rowData: any = this.gridApi?.getColumnDefs().map((item: any) => item.field)

    const invertedData = _.invert(rowData)
    Object.keys(invertedData).map((key: string) => _.set(invertedData, key, null))
    this.rowData.push(invertedData)
  }

  submit() {
    if (this.rowData.length === 0) return

    const data = CSVToArray(this.gridApi?.getDataAsCsv(), ',')

    http.post(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/data-tables/${ this.meta.dataTable.uuid }/spreadsheet`, { data })
      .then(() => {
        this.closeDialog()

        this.$store.dispatch(GlobalActions.showSnackbar, {
          type: SnackBarType.success,
          message: 'Data table was successfully updated',
        })

        this.meta.onSuccess && this.meta.onSuccess()
      })
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }
}
