














import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSize } from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { Permission } from '@/shared/configs/permission.config'
import can from '@/shared/helpers/can'
import RadioField from '@/shared/classes/form/fields/radio-field'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import _ from 'lodash'

@Component({
  components: { Form, PageHeader },
  methods: { can }
})
export default class CreateDataTableDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission

  form: FormBase = new FormBase()
    .setFiles(true)
    .setEndpoint(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/data-tables`)
    .setFields([
      new Field()
        .setKey('name')
        .setTitle('Name')
        .setSize(FieldSize.half),
      new Field()
        .setType(FieldType.textArea)
        .setRows(1)
        .setKey('description')
        .setTitle('Description')
        .setSize(FieldSize.half),
      new RadioField()
        .setKey('import_type')
        .setItems([
          new SelectItem().setValue('file').setTitle('File (CSV/XLSX)'),
          new SelectItem().setValue('text').setTitle('Text'),
        ]),
      new Field()
        .setType(FieldType.file)
        .setKey('file')
        .setTitle('Upload CSV/XLSX')
        .setVisibleIf((values: any) => {
          return _.get(values, 'import_type') === 'file'
        }),
      new Field()
        .setType(FieldType.textArea)
        .setKey('text')
        .setTitle('Import text')
        .setVisibleIf((values: any) => {
          return _.get(values, 'import_type') === 'text'
        })
        .setRows(5),
    ])
    .setSubmit(false)
    .setInitialValues({
      import_type: 'file',
    })

  async submit() {
    const errors = await this.formRef.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
