








import { Component, Inject, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import Calculator from '@/shared/modules/calculator/calculator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import CreateDataTableDialog from '@/components/datatable/CreateDataTableDialog.vue'
import EditAction from '@/components/data-table/default-actions/edit-action'
import DeleteAction from '@/components/data-table/default-actions/delete-action'
import DataTable from '@/components/data-table/DataTable.vue'
import DataTableClass from '@/shared/modules/data-table/data-table'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import http from '@/shared/http'
import EditDataTableDialog from '@/components/datatable/EditDataTableDialog.vue'
import ShowAction from '@/components/data-table/default-actions/show-action'
import DataTableSpreadSheetDialog from '@/components/datatable/DataTableSpreadSheetDialog.vue'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { DataTable }
})
export default class DataTables extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator

  table: DataTableBase = new DataTableBase()
    .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/data-tables`)
    .setHeaders([
      new DataTableHeader()
        .setTitle('Name')
        .setKey('name'),
      new DataTableHeader()
        .setTitle('Columns / Rows')
        .setKey('columns_rows_count'),
      new DataTableHeader()
        .setTitle('Description')
        .setKey('description'),
    ])
    .setActions([
      new ShowAction()
        .setAction((dataTable: DataTableClass) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: '90%',
            component: DataTableSpreadSheetDialog,
            meta: {
              client: this.selectedClient,
              calculator: this.getCalculator(),
              dataTable,
              onSuccess: () => this.tableRef.refresh(),
            }
          })
        }),
      new EditAction()
        .setAction((dataTable: DataTableClass) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: 1500,
            component: EditDataTableDialog,
            meta: {
              client: this.selectedClient,
              calculator: this.getCalculator(),
              dataTable,
              onSuccess: () => {
                this.tableRef.refresh()

                this.$store.dispatch(GlobalActions.showSnackbar, {
                  type: SnackBarType.success,
                  message: 'Data table was successfully updated',
                })
              }
            }
          })
        }),
      new DeleteAction()
        .setAction((item: DataTableClass) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: 400,
            component: AreYouSureDialog,
            meta: {
              onYes: () => http.delete(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/data-tables/${ item.uuid }`)
                .then(() => {
                  this.tableRef.refresh()

                  this.$store.dispatch(GlobalActions.showSnackbar, {
                    type: SnackBarType.success,
                    message: 'Data Table was successfully deleted'
                  })
                })
            }
          })
        })
    ])

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1060,
      component: CreateDataTableDialog,
      meta: {
        calculator: this.getCalculator(),
        client: this.selectedClient,
        onSuccess: () => {
          this.tableRef.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Data Table was successfully created'
          })
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
