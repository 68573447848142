
















import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSize } from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import PageHeader from '../PageHeader.vue'
import Form from '../form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { Permission } from '@/shared/configs/permission.config'
import can from '@/shared/helpers/can'
import HttpMethod from '@/shared/configs/http-method.config'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { Form, PageHeader },
  methods: { can }
})
export default class EditDataTableDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission

  form: FormBase = new FormBase()
    .setMethod(HttpMethod.PUT)
    .setUuid(this.meta.dataTable.uuid)
    .setEndpoint(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/data-tables`)
    .setFields([
      new Field()
        .setKey('name')
        .setTitle('Name')
        .setSize(FieldSize.half),
      new Field()
        .setType(FieldType.textArea)
        .setRows(1)
        .setKey('description')
        .setTitle('Description')
        .setSize(FieldSize.half),
    ])
    .setSubmit(false)

  async submit() {
    const errors = await this.formRef.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
