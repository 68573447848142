import Field from '@/shared/classes/form/field'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import { FieldType } from '@/shared/configs/field-type.config'

export default class RadioField extends Field {
  type: FieldType = FieldType.radio
  items: SelectItem[] = []

  setItems(items: SelectItem[]): this {
    this.items = items

    return this
  }
}
