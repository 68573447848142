const CSVToArray = (strData: any, strDelimiter: string) => {
  strDelimiter = (strDelimiter || ',')

  const objPattern = new RegExp(
    (
      // Delimiters.
      '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' +

      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +

      // Standard fields.
      '([^"\\' + strDelimiter + '\\r\\n]*))'
    ),
    'gi'
  )

  const arrData: any = [[]]

  let arrMatches = null

  while (arrMatches = objPattern.exec( strData )) {
    const strMatchedDelimiter = arrMatches[ 1 ]

    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {
      arrData.push( [] )
    }

    let strMatchedValue: any

    if (arrMatches[ 2 ]) {
      strMatchedValue = arrMatches[ 2 ].replace(
        new RegExp( "\"\"", "g" ),
        "\""
      )

    } else {
      strMatchedValue = arrMatches[ 3 ]
    }

    arrData[ arrData.length - 1 ].push( strMatchedValue )
  }

  return (arrData)
}

export default CSVToArray
