var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-cell d-flex"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.params.displayName))]),(_vm.params.enableSorting)?_c('div',{class:("custom-sort " + (_vm.sort ? 'active' : 'inactive')),on:{"click":function($event){return _vm.onSortRequested($event)}}},[_c('v-icon',{attrs:{"size":"13"}},[_vm._v(_vm._s(("mdi-arrow-" + (_vm.getSortIcon()))))])],1):_vm._e(),(_vm.params.enableMenu)?_c('div',{ref:"menuButton",staticClass:"ml-2",on:{"click":function($event){return _vm.onMenuClicked($event)}}},[_c('v-icon',{attrs:{"size":"13"}},[_vm._v("mdi-filter")])],1):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"cursor-pointer settings-column ml-1",attrs:{"size":"15"}},on),[_vm._v("mdi-cog")])]}}])},[_c('v-list',[_c('v-dialog',{ref:"dialog",attrs:{"scrollable":"","max-width":500,"content-class":"bg-main-blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g({attrs:{"link":"","dense":""}},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Edit column")}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"pa-10 position-relative"},[_c('PageHeader',{staticClass:"mb-3",attrs:{"title":"Edit column"}}),_c('div',{staticClass:"bg-main-white px-5 py-6 border-4"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","placeholder":"Column name","error":_vm.error},model:{value:(_vm.columnName),callback:function ($$v) {_vm.columnName=$$v},expression:"columnName"}})],1),_c('div',{staticClass:"d-flex justify-end mt-6"},[_c('div',[_c('v-btn',{staticClass:"main outlined-button color-primary",domProps:{"textContent":_vm._s("Cancel")},on:{"click":function($event){_vm.dialog = false}}}),_c('v-btn',{staticClass:"main ml-5",attrs:{"color":"secondary"},domProps:{"textContent":_vm._s("Submit")},on:{"click":_vm.editColumnSubmit}})],1)])],1)]),_c('v-dialog',{ref:"dialog",attrs:{"scrollable":"","max-width":500,"content-class":"bg-main-blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g({attrs:{"link":"","dense":""}},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Insert 1 left")}})],1)]}}]),model:{value:(_vm.dialogLeft),callback:function ($$v) {_vm.dialogLeft=$$v},expression:"dialogLeft"}},[_c('div',{staticClass:"pa-10 position-relative"},[_c('PageHeader',{staticClass:"mb-3",attrs:{"title":"Insert 1 left"}}),_c('div',{staticClass:"bg-main-white px-5 py-6 border-4"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","placeholder":"Column name","error":_vm.error},model:{value:(_vm.columnNameLeft),callback:function ($$v) {_vm.columnNameLeft=$$v},expression:"columnNameLeft"}})],1),_c('div',{staticClass:"d-flex justify-end mt-6"},[_c('div',[_c('v-btn',{staticClass:"main outlined-button color-primary",domProps:{"textContent":_vm._s("Cancel")},on:{"click":function($event){_vm.dialogLeft = false}}}),_c('v-btn',{staticClass:"main ml-5",attrs:{"color":"secondary"},domProps:{"textContent":_vm._s("Submit")},on:{"click":function($event){return _vm.saveColumn('left')}}})],1)])],1)]),_c('v-dialog',{ref:"dialog",attrs:{"scrollable":"","max-width":500,"content-class":"bg-main-blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g({attrs:{"link":"","dense":""}},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Insert 1 right")}})],1)]}}]),model:{value:(_vm.dialogRight),callback:function ($$v) {_vm.dialogRight=$$v},expression:"dialogRight"}},[_c('div',{staticClass:"pa-10 position-relative"},[_c('PageHeader',{staticClass:"mb-3",attrs:{"title":"Insert 1 right"}}),_c('div',{staticClass:"bg-main-white px-5 py-6 border-4"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","placeholder":"Column name","error":_vm.error},model:{value:(_vm.columnNameRight),callback:function ($$v) {_vm.columnNameRight=$$v},expression:"columnNameRight"}})],1),_c('div',{staticClass:"d-flex justify-end mt-6"},[_c('div',[_c('v-btn',{staticClass:"main outlined-button color-primary",domProps:{"textContent":_vm._s("Cancel")},on:{"click":function($event){_vm.dialogRight = false}}}),_c('v-btn',{staticClass:"main ml-5",attrs:{"color":"secondary"},domProps:{"textContent":_vm._s("Submit")},on:{"click":function($event){return _vm.saveColumn('right')}}})],1)])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }