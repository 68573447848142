














































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import _ from 'lodash'
import insert from '@/shared/helpers/insert.helper'

@Component({
  components: { PageHeader }
})
export default class SpreadSheetHeaderComponent extends Vue {
  params: any
  sort: any = null
  dialog: boolean = false
  dialogRight: boolean = false
  dialogLeft: boolean = false
  columnName: string = ''
  columnNameRight: string = ''
  columnNameLeft: string = ''
  error: boolean = false

  @Watch('columnNameRight') private handleColumnNameRight() {
    this.error = false
  }

  @Watch('columnNameLeft') private handleColumnNameLeft() {
    this.error = false
  }

  @Watch('columnName') private handleColumnName() {
    this.error = false
  }

  created() {
    this.columnName = this.params.displayName
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.$refs.menuButton)
  }

  editColumnSubmit() {
    let columnDefs = this.params.api.getColumnDefs()

    const trimmed = this.columnName.trim()

    if (/^\d+$/.test(trimmed)) {
      this.error = true

      return
    }

    const columnNameExists: boolean = columnDefs
        .filter((colDef: any) => colDef.headerName === trimmed).length > 0

    if (trimmed === '' || columnNameExists) {
      this.error = true

      return
    }

    columnDefs.forEach((colDef: any) => {
      if (this.params.column.colId === colDef.colId) colDef.headerName = this.columnName
    })

    this.params.api.setColumnDefs(columnDefs)
    this.params.columnApi.autoSizeAllColumns()
  }

  saveColumn(type: string) {
    let columnDefs: any = _.cloneDeep(this.params.api.getColumnDefs())
    let newColumnDefs: any = []

    if (type === 'right') newColumnDefs = this.insertRightColumn(columnDefs)
    if (type === 'left') newColumnDefs = this.insertLeftColumn(columnDefs)

    if (this.error) return

    this.params.api.setColumnDefs([])
    this.params.api.setColumnDefs(newColumnDefs)
    this.params.columnApi.autoSizeAllColumns()
    this.error = false
  }

  insertRightColumn(columnDefs: any[]) {
    let newColumnDefs: any[] = []
    const columnNameExists: boolean = columnDefs.filter((colDef: any) => colDef.colId === this.columnNameRight).length > 0

    if (this.columnNameRight === '' || columnNameExists) {
      this.error = true

      return
    }

    columnDefs.forEach((colDef: any, index: number) => {
      if (this.params.column.colId === colDef.colId) {
        newColumnDefs = insert(columnDefs, index + 1, { field: this.columnNameRight, headerName: this.columnNameRight })
      }
    })

    return newColumnDefs
  }

  insertLeftColumn(columnDefs: any[]) {
    let newColumnDefs: any[] = []
    const columnNameExists: boolean = columnDefs.filter((colDef: any) => colDef.colId === this.columnNameLeft).length > 0

    if (this.columnNameLeft === '' || columnNameExists) {
      this.error = true

      return
    }

    columnDefs.forEach((colDef: any, index: number) => {
      if (this.params.column.colId === colDef.colId) {
        newColumnDefs = insert(columnDefs, index, { field: this.columnNameLeft, headerName: this.columnNameLeft })
      }
    })

    return newColumnDefs
  }

  onSortRequested(event: any) {
    if (this.sort === 'desc') {
      this.sort = null

      return
    }
    if (this.sort === 'asc') this.sort = 'desc'
    if (! this.sort) this.sort = 'asc'

    this.params.setSort(this.sort, event.shiftKey)
  }

  getSortIcon() {
    let icon: string = 'up'

    switch (this.sort) {
      case 'asc':
        icon = 'up'
        break
      case 'desc':
        icon = 'down'
        break
    }

    return icon
  }
}
